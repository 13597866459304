import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const MyAnalysisIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge("fill-[#C2D6D9]", props.className)}
    >
      <path d="M14.0746 13.1499H1.85088V0.925442C1.85088 0.68 1.75338 0.44461 1.57983 0.271056C1.40627 0.0975017 1.17088 0 0.925442 0C0.68 0 0.44461 0.0975017 0.271056 0.271056C0.0975017 0.44461 0 0.68 0 0.925442L0 14.075C0.000220234 14.3203 0.0977222 14.5556 0.271127 14.7291C0.444531 14.9027 0.679685 15.0004 0.925026 15.0008H14.0746C14.1961 15.0008 14.3164 14.9769 14.4287 14.9304C14.541 14.8839 14.643 14.8157 14.7289 14.7298C14.8149 14.6438 14.883 14.5418 14.9296 14.4295C14.9761 14.3173 15 14.1969 15 14.0754C15 13.9539 14.9761 13.8335 14.9296 13.7212C14.883 13.609 14.8149 13.5069 14.7289 13.421C14.643 13.3351 14.541 13.2669 14.4287 13.2204C14.3164 13.1739 14.1961 13.1499 14.0746 13.1499" />
      <path d="M3.99365 10.9841C4.05184 10.9843 4.1099 10.9787 4.16698 10.9674C4.40067 10.9233 4.6074 10.7885 4.742 10.5924L7.42708 6.78896L9.92131 8.55317C10.1187 8.68643 10.3608 8.73641 10.5948 8.69225C10.8289 8.64809 11.0361 8.51336 11.1713 8.31733L14.3856 3.76471C14.5268 3.5621 14.5869 3.31387 14.5538 3.06912C14.5208 2.82437 14.3971 2.60093 14.2073 2.443L14.1431 2.3955C13.9455 2.26241 13.7033 2.21269 13.4692 2.25717C13.2352 2.30164 13.0281 2.43671 12.8931 2.63301L10.2072 6.43478L7.71542 4.67223C7.51771 4.5378 7.27484 4.48706 7.03985 4.53109C6.80486 4.57512 6.59684 4.71035 6.46122 4.90724L3.24696 9.4607C3.1022 9.67546 3.04641 9.93801 3.09133 10.1931C3.13625 10.4482 3.27838 10.6758 3.4878 10.8282C3.63742 10.9294 3.81384 10.9837 3.99448 10.9841" />
    </svg>
  );
};
