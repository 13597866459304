import { Translation } from "@/data/lang";
import { links } from "@/data/links";
import { externalLinks } from "@/data/links/external-links";

import { DiscordIcon } from "ui/icon/shared";

import FacebookIcon from "../components/FacebookIcon";
import LineIcon from "../components/LineIcon";

export const getTermsData = (t: Translation) => [
  {
    to: externalLinks.privacyPolicy,
    title: t.layout.footer.privacyPolicy,
    label: getFooterLinkLabel({ name: "privacy policy" }),
  },
  {
    to: externalLinks.termsOfUse,
    title: t.layout.footer.termsOfUse,
    label: getFooterLinkLabel({ name: "terms of service" }),
  },
];

type LinksProp = {
  title: string;
  links: {
    title: string;
    url: string;
    label: string;
    icon?: React.ReactNode;
  }[];
}[];

export const getFooterLinks = (t: Translation): LinksProp => [
  {
    title: t.layout.footer.activeInvestment,
    links: [
      {
        title: t.layout.footer.stockMining,
        url: links.stockMining,
        label: getFooterLinkLabel({ name: "stock mining" }),
      },
      {
        title: t.layout.footer.pricing,
        url: links.stockMiningPricing,
        label: getFooterLinkLabel({ name: "stock mining pricing" }),
      },
    ],
  },
  {
    title: t.layout.footer.passiveInvestment,
    links: [
      {
        title: t.layout.footer.roboAdvisor,
        url: links.roboAdvisor,
        label: getFooterLinkLabel({ name: "robo advisor" }),
      },
      {
        title: t.layout.footer.technology,
        url: links.technology,
        label: getFooterLinkLabel({ name: "technology" }),
      },
      {
        title: t.layout.footer.help,
        url: links.help,
        label: getFooterLinkLabel({ name: "help" }),
      },
    ],
  },
  {
    title: t.layout.footer.about,
    links: [
      {
        title: "Blog",
        url: externalLinks.blog,
        label: getFooterLinkLabel({ name: "blog" }),
      },
      {
        title: "Youtube",
        url: externalLinks.youtube,
        label: getFooterLinkLabel({ name: "youtube" }),
      },
      {
        title: t.layout.footer.company,
        url: links.about,
        label: getFooterLinkLabel({ name: "about" }),
      },
      {
        title: t.layout.footer.contact,
        url: links.contact,
        label: getFooterLinkLabel({ name: "contact" }),
      },
    ],
  },
  {
    title: t.layout.footer.socialMedia,
    links: [
      {
        title: `${t.layout.footer.line} - ${t.layout.footer.stockMining}`,
        url: externalLinks.lineStockmining,
        label: getFooterLinkLabel({ name: "stock-mining line" }),
        icon: <LineIcon />,
      },
      {
        title: `${t.layout.footer.line} - ${t.layout.footer.roboAdvisor}`,
        url: externalLinks.lineRoboAdvisor,
        label: getFooterLinkLabel({ name: "robo-advisor line" }),
        icon: <LineIcon />,
      },
      // {
      //   title: t.layout.footer.telegram,
      //   url: externalLinks.telegram,
      //   label: getFooterLinkLabel({ name: "telegram" }),
      //   icon: <TelegramIcon className="fill-[#2FA3D7]" />,
      // },
      {
        title: t.layout.footer.discord,
        url: externalLinks.discord,
        label: getFooterLinkLabel({ name: "discord" }),
        icon: <DiscordIcon />,
      },
      {
        title: t.layout.footer.facebook,
        url: externalLinks.facebook,
        label: getFooterLinkLabel({ name: "facebook" }),
        icon: <FacebookIcon />,
      },
    ],
  },
];

export const getFooterLinkLabel = ({ name }: { name: string }) =>
  `go to ${name} page from footer`;
