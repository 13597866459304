import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const MyScreenerIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge("fill-[#C2D6D9]", props.className)}
    >
      <path d="M14.047 11.9412H11.4657C11.2932 11.5944 11.0274 11.3025 10.6981 11.0985C10.3688 10.8945 9.98912 10.7864 9.60175 10.7864C9.21439 10.7864 8.83471 10.8945 8.50542 11.0985C8.17613 11.3025 7.91029 11.5944 7.73781 11.9412H0.959846C0.707869 11.9412 0.466213 12.0413 0.288038 12.2195C0.109863 12.3977 0.00976563 12.6393 0.00976562 12.8913C0.00976563 13.1433 0.109863 13.3849 0.288038 13.5631C0.466213 13.7413 0.707869 13.8414 0.959846 13.8414H7.74031C7.91279 14.1882 8.17862 14.4801 8.50791 14.6841C8.8372 14.8881 9.21689 14.9962 9.60425 14.9962C9.99161 14.9962 10.3713 14.8881 10.7006 14.6841C11.0299 14.4801 11.2957 14.1882 11.4682 13.8414H14.0495C14.3015 13.8414 14.5431 13.7413 14.7213 13.5631C14.8995 13.3849 14.9996 13.1433 14.9996 12.8913C14.9996 12.6393 14.8995 12.3977 14.7213 12.2195C14.5431 12.0413 14.3015 11.9412 14.0495 11.9412" />
      <path d="M14.0474 6.53131H3.97457C3.761 6.11012 3.41175 5.77317 2.98318 5.57483C2.55461 5.37649 2.07171 5.32833 1.61241 5.43812C1.15311 5.54791 0.744197 5.80925 0.451653 6.17997C0.159109 6.55068 0 7.00915 0 7.48139C0 7.95363 0.159109 8.4121 0.451653 8.78282C0.744197 9.15353 1.15311 9.41487 1.61241 9.52466C2.07171 9.63445 2.55461 9.58629 2.98318 9.38795C3.41175 9.18961 3.761 8.85266 3.97457 8.43147H14.0499C14.3019 8.43147 14.5436 8.33138 14.7217 8.1532C14.8999 7.97503 15 7.73337 15 7.48139C15 7.22941 14.8999 6.98776 14.7217 6.80958C14.5436 6.63141 14.3019 6.53131 14.0499 6.53131" />
      <path d="M0.959846 3.05495H7.74031C7.91279 3.4018 8.17862 3.69364 8.50791 3.89765C8.8372 4.10166 9.21689 4.20974 9.60425 4.20974C9.99161 4.20974 10.3713 4.10166 10.7006 3.89765C11.0299 3.69364 11.2957 3.4018 11.4682 3.05495H14.0495C14.1742 3.05495 14.2978 3.03038 14.4131 2.98263C14.5283 2.93488 14.6331 2.8649 14.7213 2.77668C14.8095 2.68845 14.8795 2.58372 14.9272 2.46845C14.975 2.35318 14.9996 2.22964 14.9996 2.10487C14.9996 1.9801 14.975 1.85656 14.9272 1.74129C14.8795 1.62602 14.8095 1.52128 14.7213 1.43306C14.6331 1.34484 14.5283 1.27485 14.4131 1.22711C14.2978 1.17936 14.1742 1.15479 14.0495 1.15479H11.4682C11.2957 0.807942 11.0299 0.516101 10.7006 0.312092C10.3713 0.108083 9.99161 0 9.60425 0C9.21689 0 8.8372 0.108083 8.50791 0.312092C8.17862 0.516101 7.91279 0.807942 7.74031 1.15479H0.959846C0.707869 1.15479 0.466213 1.25489 0.288038 1.43306C0.109863 1.61124 0.00976563 1.85289 0.00976562 2.10487C0.00976562 2.35685 0.109863 2.5985 0.288038 2.77668C0.466213 2.95485 0.707869 3.05495 0.959846 3.05495Z" />
    </svg>
  );
};
