const FacebookIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C20 4.47704 15.523 0 10 0C4.47704 0 0 4.47704 0 10C0 14.9911 3.6563 19.1289 8.43704 19.8785V12.8904H5.89778V10H8.43704V7.79704C8.43704 5.29037 9.93037 3.90667 12.2148 3.90667C13.3096 3.90667 14.4533 4.10222 14.4533 4.10222V6.56296H13.1926C11.9511 6.56296 11.563 7.33333 11.563 8.12444V10H14.3363L13.8933 12.8904H11.563V19.8785C16.3437 19.1289 20 14.9911 20 10Z"
      fill="#4676ED"
    />
  </svg>
);

export default FacebookIcon;
