import { Lang } from "@/i18n-config";

const socialLinks = {
  facebook: "https://www.facebook.com/growin.tv",
  lineStockmining: "https://line.me/R/ti/p/@776lekuw?oat_content=url",
  lineRoboAdvisor: "https://line.me/R/ti/p/@382lknet?oat_content=url",
  discord: "https://discord.gg/4MKuDt2Xrx",
  telegram: "https://t.me/growin_news_whisper",
  youtube: "https://www.youtube.com/channel/UCRtLkb13ccH4YSY90HCQauA",
  lineNotification: "https://lin.ee/FaFfCxr",
  blog: "https://blog.growin.tv",
};

const appLinks = {
  growinAppStore: "https://apps.apple.com/tw/app/growin/id1462382649",
  growinGooglePlay:
    "https://play.google.com/store/apps/details?id=com.tradingvalley.growin",
};

const statementLinks = {
  privacyPolicy: "https://file.growin.tv/policy/privacy-policy.html",
  termsOfUse: "https://file.growin.tv/policy/terms-and-conditions.html",
  riskDisclosureStatement: ({ lang }: { lang: Lang }) =>
    `https://files.growin.tv/Growin_Disclaimer_${lang}.pdf`,
};

const blogLinks = {
  stockReversalTiming: "https://blog.growin.tv/stock-reversal-timing/",
};

export const externalLinks = {
  ...socialLinks,
  ...appLinks,
  ...statementLinks,
  ...blogLinks,
};
