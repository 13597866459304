import { usePathname } from "next/navigation";
import { useMemo } from "react";

export const usePath = () => {
  const pathname = usePathname() ?? "/";
  const paths = useMemo(() => pathname.split("/"), [pathname]);

  return useMemo(() => {
    let isStockMiningPage = false;

    const pathSet = new Set(paths);
    paths.forEach((path) => {
      if (path.indexOf("stockmining") === 0) isStockMiningPage = true;
    });

    return {
      isMyPage: pathSet.has("my"),
      isAnalysisPage: pathSet.has("analysis"),
      isScreenerPage: pathSet.has("screener"),
      is13FPage: pathSet.has("13f"),
      isNotificationPage: pathSet.has("notification"),
      isNotificationLinePage:
        pathSet.has("notification") && pathSet.has("line"),
      isStripePage: pathSet.has("stripe"),
      isAuthPage: pathSet.has("auth"),
      isMarketFearGreedPage: pathSet.has("fear-and-greed"),
      isMarketEarlyWarningPage: pathSet.has("market-early-warning-indicator"),
      isStockMiningPage,
      isTradingViewAdvancedChartPage: pathSet.has("tradingview-advanced-chart"),
      lastRoute: paths[paths.length - 1],
    };
  }, [paths]);
};
