import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const HelpIcon = (props: ComponentProps<"svg">) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={twMerge("fill-[#C2D6D9]", props.className)}
  >
    <path d="M7.49918 0C3.3567 0 0 2.94424 0 6.57646C0 8.70394 1.15259 10.5934 2.93772 11.7965C2.77307 12.5986 2.43234 13.5817 1.72644 14.2957C1.71666 14.3055 1.71992 14.3202 1.73459 14.3234C2.00196 14.374 4.05282 14.6902 5.56896 12.9345C6.1852 13.0779 6.83241 13.1545 7.50081 13.1545C11.6417 13.1545 15 10.2103 15 6.57809C15 2.94587 11.64 0 7.49918 0ZM7.42093 9.98044H7.35246C7.05901 9.98044 6.821 9.74242 6.821 9.44897C6.821 9.15553 7.05901 8.91751 7.35246 8.91751H7.42093C7.71438 8.91751 7.9524 9.15553 7.9524 9.44897C7.9524 9.74242 7.71438 9.98044 7.42093 9.98044ZM9.17509 6.15585C8.95174 6.48679 8.66808 6.78839 8.32736 7.06228C8.15455 7.21389 8.04532 7.35898 8.0013 7.49592C7.95892 7.62471 7.93772 7.81219 7.93446 8.0551C7.93446 8.07793 7.91653 8.09912 7.89208 8.09912H6.86828C6.84382 8.09912 6.82426 8.07793 6.82589 8.05347C6.83241 7.64102 6.88132 7.34594 6.97587 7.16498C7.07369 6.9775 7.28888 6.74274 7.62471 6.45908C7.80404 6.28464 7.95566 6.1151 8.07956 5.95207C8.30127 5.65862 8.43332 5.29671 8.39257 4.93153C8.36811 4.71307 8.29149 4.53864 8.16107 4.40822C8.0013 4.24519 7.77307 4.16368 7.47799 4.16368C7.2253 4.16368 7.01011 4.23378 6.83404 4.37072C6.66612 4.50277 6.57809 4.69351 6.56994 4.94457C6.56994 4.96739 6.552 4.98533 6.52918 4.98533L5.48582 4.98859V4.95761C5.47767 4.41637 5.65862 3.99413 6.03195 3.6909C6.40528 3.38768 6.88621 3.23606 7.47799 3.23606C8.11705 3.23606 8.61591 3.39909 8.97294 3.72514C9.32996 4.05119 9.50929 4.50277 9.50929 5.08151C9.50929 5.46788 9.3968 5.82654 9.17346 6.15748L9.17509 6.15585Z" />
  </svg>
);
