import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const MarketAnalysisIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width={15}
      height={15}
      preserveAspectRatio="xMidYMid meet"
      {...props}
      viewBox="0 0 18 15"
      className={twMerge("fill-[#C2D6D9]", props.className)}
    >
      <path d="M13.401 8.318a1.356 1.356 0 00-.256-1.002 1.325 1.325 0 00-1.87-.264l-3.987 3.05c-.588.449-.705 1.299-.261 1.894a1.32 1.32 0 001.87.264l3.987-3.05c.284-.217.468-.534.517-.892z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2.013a9.064 9.064 0 013.314 4.091 9.197 9.197 0 01.512 5.268 9.169 9.169 0 01-1.177 3.026h-2.972l.032-.034a6.755 6.755 0 001.823-3.455 6.807 6.807 0 00-.38-3.898A6.706 6.706 0 0012.7 3.983 6.578 6.578 0 009 2.846a6.578 6.578 0 00-3.7 1.137 6.71 6.71 0 00-2.453 3.028 6.807 6.807 0 00-.379 3.898 6.755 6.755 0 001.855 3.49H1.351a9.17 9.17 0 01-1.177-3.027 9.197 9.197 0 01.512-5.268 9.063 9.063 0 013.315-4.091A8.888 8.888 0 019 .476c1.786 0 3.514.532 5 1.537z"
      />
    </svg>
  );
};
