import { UserAvatar } from "@/components/user-avatar";
import { Translation } from "@/data/lang";
import { links } from "@/data/links";
import { Lang } from "@/i18n-config";

import { Icons } from "./icons";
import { GuestMenu } from "./interface/guest-menu";
import { UserMenu } from "./interface/user-menu";

const getNavbarLinkLabel = ({ name }: { name: string }) =>
  `go to ${name} page from navbar`;

export const getUserMenuItems = ({
  t,
  lang,
  isLogin,
  onLogout,
  email,
}: {
  t: Translation;
  lang: Lang;
  isLogin: boolean;
  onLogout: () => void;
  email: string;
}): GuestMenu | UserMenu => {
  if (!isLogin) {
    return {
      title: t.layout.navbar.userMenu,
      options: [
        {
          title: t.layout.navbar.login,
          href: `/${lang}${links.authLogin}`,
          ariaLabel: getNavbarLinkLabel({ name: "auth login" }),
        },
        {
          title: t.layout.navbar.getStarted,
          href: `/${lang}${links.myAnalysisSearch}`,
          blank: true,
          ariaLabel: getNavbarLinkLabel({ name: "my analysis search" }),
        },
      ],
    };
  }

  return {
    title: t.layout.navbar.userMenu,
    icon: <UserAvatar width={30} height={30} />,
    sections: [
      {
        title: email,
        showUsername: true,
        options: [
          {
            to: `/${lang}${links.myAnalysisSearch}`,
            title: t.layout.navbar.analysis,
            icon: Icons.myAnalysis,
            ariaLabel: getNavbarLinkLabel({ name: "my analysis search" }),
          },
          {
            to: `/${lang}${links.myScreener({ country: "us" })}`,
            title: t.layout.navbar.aiStockSelection,
            icon: Icons.myScreener,
            ariaLabel: getNavbarLinkLabel({ name: "my us-screener" }),
          },
          {
            to: `/${lang}${links.myWinwingpt}`,
            title: t.layout.navbar.myWinwinGpt,
            icon: Icons.myWinwinGpt,
            ariaLabel: getNavbarLinkLabel({ name: "my winwin-gpt" }),
          },
          {
            to: `/${lang}${links.myMarketAnalysisFearAndGreed}`,
            title: t.layout.navbar.marketAnalysis,
            icon: Icons.marketAnalysis,
            ariaLabel: getNavbarLinkLabel({ name: "my market analysis" }),
          },
          {
            to: `/${lang}${links.myMediaCategory({
              category: "website-tutorials",
            })}`,
            title: t.layout.navbar.knowledgeBase,
            icon: Icons.myMediaCenter,
            ariaLabel: getNavbarLinkLabel({ name: "my media-center" }),
          },
          {
            to: `/${lang}${links.home}`,
            title: t.layout.navbar.logout,
            onClick: () => onLogout(),
            icon: Icons.logout,
            ariaLabel: "logout",
          },
        ],
      },
    ],
  };
};
