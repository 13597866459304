import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const StockMiningIcon = (props: ComponentProps<"svg">) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={twMerge("fill-[#C2D6D9]", props.className)}
  >
    <path d="M2.46344 9.58525C2.19295 9.57346 1.92885 9.66948 1.72915 9.85223C1.52945 10.035 1.4105 10.2895 1.39844 10.5598V14.0226C1.4105 14.293 1.52945 14.5475 1.72915 14.7303C1.92885 14.913 2.19295 15.009 2.46344 14.9972C2.73392 15.009 2.99803 14.913 3.19772 14.7303C3.39742 14.5475 3.51637 14.293 3.52844 14.0226V10.5576C3.51637 10.2872 3.39742 10.0327 3.19772 9.84998C2.99803 9.66723 2.73392 9.57121 2.46344 9.583" />
    <path d="M7.2818 8.99147C7.01131 8.97968 6.74721 9.0757 6.54751 9.25844C6.34781 9.44119 6.22886 9.6957 6.2168 9.96606V14.0264C6.24124 14.2918 6.36401 14.5386 6.56103 14.7182C6.75805 14.8979 7.01511 14.9975 7.2818 14.9975C7.54848 14.9975 7.80554 14.8979 8.00257 14.7182C8.19959 14.5386 8.32236 14.2918 8.3468 14.0264V9.96381C8.33453 9.69352 8.21553 9.43913 8.01588 9.25642C7.81622 9.07372 7.55223 8.97763 7.2818 8.98922" />
    <path d="M12.1548 6.80791C11.8844 6.79611 11.6203 6.89214 11.4206 7.07488C11.2209 7.25763 11.1019 7.51214 11.0898 7.7825V14.0221C11.1143 14.2876 11.2371 14.5343 11.4341 14.714C11.6311 14.8936 11.8882 14.9932 12.1548 14.9932C12.4215 14.9932 12.6786 14.8936 12.8756 14.714C13.0726 14.5343 13.1954 14.2876 13.2198 14.0221V7.78025C13.2078 7.50989 13.0888 7.25538 12.8891 7.07263C12.6894 6.88989 12.4253 6.79386 12.1548 6.80566" />
    <path d="M14.2095 0.000794508H10.35C10.084 -0.00977295 9.82465 0.0854235 9.62871 0.265548C9.43277 0.445673 9.31619 0.696055 9.3045 0.961893C9.316 1.22786 9.43249 1.47844 9.62845 1.65872C9.82442 1.83901 10.0839 1.93431 10.35 1.92374H11.532C10.1947 3.32557 8.57486 4.42758 6.77964 5.15688C4.98441 5.88617 3.05473 6.22611 1.11825 6.15422C0.979347 6.14268 0.839543 6.15935 0.707249 6.20321C0.574955 6.24708 0.452905 6.31724 0.34844 6.40947C0.243975 6.50171 0.159254 6.61411 0.0993737 6.73992C0.0394934 6.86574 0.005691 7.00235 0 7.14156C0.0124377 7.41129 0.129352 7.6656 0.326011 7.8507C0.522671 8.03581 0.783652 8.13719 1.05375 8.1334C8.79675 8.33281 12.8783 3.59929 12.8783 3.59929V4.84902C12.9017 5.11412 13.0236 5.36085 13.2199 5.54057C13.4163 5.72029 13.6729 5.81997 13.9391 5.81997C14.2054 5.81997 14.462 5.72029 14.6583 5.54057C14.8547 5.36085 14.9766 5.11412 15 4.84902V0.727241C14.9912 0.526236 14.9031 0.336904 14.7549 0.200735C14.6068 0.0645656 14.4106 -0.0073332 14.2095 0.000794508Z" />
  </svg>
);
