import {
  ChatIcon,
  HelpIcon,
  MarketAnalysisIcon,
  MyAnalysisIcon,
  MyBlogIcon,
  MyMediaIcon,
  MyNotificationIcon,
  MyScreenerIcon,
  RoboAdvisorIcon,
  StockMiningIcon,
  StockMiningPricingIcon,
  TechnologyIcon,
} from "ui/icon/features";
import { LogoutIcon } from "ui/icon/shared";

export const Icons = {
  stockMining: StockMiningIcon,
  stockMiningPricing: StockMiningPricingIcon,
  roboAdvisor: RoboAdvisorIcon,
  technology: TechnologyIcon,
  help: HelpIcon,
  logout: LogoutIcon,
  myAnalysis: MyAnalysisIcon,
  myScreener: MyScreenerIcon,
  myWinwinGpt: ChatIcon,
  myNotification: MyNotificationIcon,
  marketAnalysis: MarketAnalysisIcon,
  myMediaCenter: MyMediaIcon,
  myBlog: MyBlogIcon,
};
