import React from "react";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

const ReportConclusionIcon = (props: ComponentProps<"svg">) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    {...props}
    className={twMerge("fill-[#C2D6D9]", props.className)}
  >
    <g clipPath="url(#prefix__clip0_863_13745)">
      <path d="M17.273 12.273c0-.5-.41-.91-.91-.91h-3.636c-.5 0-.909.41-.909.91V25h5.455V12.273zM25 14.09c0-.5-.41-.91-.91-.91h-3.636c-.5 0-.909.41-.909.91V25H25V14.09zm3.636 13.182H1.364c-.755 0-1.364.609-1.364 1.363C0 29.391.61 30 1.364 30h27.272c.755 0 1.364-.61 1.364-1.364 0-.754-.61-1.363-1.364-1.363zM9.546 16.818c0-.5-.41-.909-.91-.909H5c-.5 0-.91.41-.91.91V25h5.455v-8.182zM26.318 0l-4.091.582c-.655.09-.927.9-.455 1.373l.946.945-3.3 3.3L14 2.09c-.5-.38-1.2-.363-1.691.037l-8.637 7.1a1.368 1.368 0 00-.19 1.918 1.361 1.361 0 001.918.191l7.8-6.418 5.509 4.173a1.37 1.37 0 001.79-.118l4.137-4.137.627.628a.81.81 0 001.373-.455l.582-4.09a.801.801 0 00-.91-.91l.01-.009z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0_863_13745">
        <path fill="#fff" d="M0 0h30v30H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const ReportConclusion13FIcon = React.memo(ReportConclusionIcon);
