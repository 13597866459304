"use client";

import { usePathname, useRouter } from "next/navigation";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

import { useLang } from "@/hooks/use-lang";

const colorSchemes = {
  green: {
    buttonClass: "text-gray-900 disabled:text-emerald-500 disabled:font-medium",
    dividerClass: "divide-gray-900",
  },
  white: {
    buttonClass: "text-white/50 disabled:text-white disabled:font-medium",
    dividerClass: "divide-white",
  },
  blue: {
    buttonClass: "text-gray-900 disabled:text-sky-700 disabled:font-medium",
    dividerClass: "divide-gray-900",
  },
};

type Props = {
  colorScheme: keyof typeof colorSchemes;
} & ComponentProps<"div">;

export const LangSwitch = ({ colorScheme, ...props }: Props) => {
  const pathname = usePathname();
  const { lang } = useLang();
  const { buttonClass, dividerClass } = colorSchemes[colorScheme];
  const router = useRouter();

  return (
    <div
      {...props}
      className={twMerge(
        "divide flex w-min items-center justify-evenly divide-x text-sm",
        dividerClass,
        props.className
      )}
    >
      <button
        className={twMerge("pr-3", buttonClass)}
        onClick={() => {
          router.replace(pathname.replace("/en", "/zh"));
        }}
        disabled={lang === "zh"}
        aria-label="switch to chinese"
      >
        繁
      </button>
      <button
        className={twMerge("pl-3", buttonClass)}
        onClick={() => {
          router.replace(pathname.replace("/zh", "/en"));
        }}
        disabled={lang === "en"}
        aria-label="switch to english"
      >
        EN
      </button>
    </div>
  );
};
