import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const RoboAdvisorIcon = (props: ComponentProps<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    {...props}
    className={twMerge("fill-[#C2D6D9]", props.className)}
  >
    <path
      d="M74.746-1463H65.4a2.658 2.658 0 0 0-2.47 1.636q-.031.077-.06.158s-2.443 7.956-1.739 11.383a1.172 1.172 0 0 0 2.24.231 5.089 5.089 0 0 0 .194-.545 4.973 4.973 0 0 0 .173-.9c.182-1.735 1.089-8.572 4.157-9.263a.277.277 0 0 1 .311.386 22.219 22.219 0 0 0-.978 2.531 8.779 8.779 0 0 1-.475 1.306 4.937 4.937 0 0 1-.987 1.477 2.921 2.921 0 0 0-.894 1.576l-.176.92a.149.149 0 0 0 .267.117l.6-.86q.056-.08.108-.162a4.19 4.19 0 0 1 2.229-1.838.129.129 0 0 1 .117.211c-.295.372-.693 1.078-.962 1.426-.042.053-.108.167-.132.2a14.652 14.652 0 0 1-1.28 1.486 7.332 7.332 0 0 0-.7.765 1.76 1.76 0 0 0 .317 2.557s2.044 1.1 2.42-2.261a7.916 7.916 0 0 1 .142-.863c.329-1.459 1.4-5.336 3.587-5.615a.177.177 0 0 1 .184.233 13.074 13.074 0 0 1-2.12 4.057 4.777 4.777 0 0 0-1.033 2.046 2.172 2.172 0 0 0 .507 2.233 2.316 2.316 0 0 0 2.24.168 3.506 3.506 0 0 0 2.04-2.417 17.808 17.808 0 0 0 .57-4.016s.067-4.1 1.861-6.2a2.268 2.268 0 0 0 .162-.24 1.274 1.274 0 0 0-1.074-1.923"
      transform="translate(-61 1463)"
      clipRule="evenodd"
    >
      <path d="M0-689.808h787.305V-1849H0z" transform="translate(0 1849)">
        <path
          d="M56-1468h21.151v21.269H56z"
          transform="translate(-21.555 1706.86)"
        />
      </path>
    </path>
  </svg>
);
