const LineIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#00B900"
    />
    <path
      d="M16.7831 9.48301C16.7831 6.43412 13.7253 3.95264 9.96826 3.95264C6.21122 3.95264 3.15344 6.43412 3.15344 9.48301C3.15344 12.2163 5.57715 14.5067 8.8527 14.9393C9.07492 14.9867 9.37715 15.086 9.4527 15.2756C9.52085 15.4475 9.49715 15.7171 9.47492 15.8919C9.47492 15.8919 9.39492 16.3734 9.37715 16.4756C9.34752 16.6475 9.24085 17.1497 9.96678 16.843C10.6942 16.5363 13.8912 14.5319 15.3209 12.886C16.309 11.803 16.7816 10.7037 16.7816 9.48301"
      fill="white"
    />
    <path
      d="M8.58317 8.00903H8.10465C8.03205 8.00903 7.97131 8.06829 7.97131 8.14088V11.1098C7.97131 11.1824 8.03057 11.2416 8.10465 11.2416H8.58317C8.65576 11.2416 8.7165 11.1824 8.7165 11.1098V8.14088C8.7165 8.06829 8.65724 8.00903 8.58317 8.00903Z"
      fill="#00B900"
    />
    <path
      d="M11.8735 8.00903H11.395C11.321 8.00903 11.2617 8.06829 11.2617 8.14088V9.90533L9.90021 8.06829C9.90021 8.06829 9.8928 8.0594 9.88984 8.05496C9.88984 8.05496 9.88391 8.04903 9.88243 8.04607C9.88243 8.04607 9.88095 8.04459 9.87947 8.04311C9.87651 8.04163 9.87502 8.03866 9.87206 8.03718C9.87206 8.03718 9.8691 8.0357 9.8691 8.03422C9.86614 8.03274 9.86465 8.03126 9.86169 8.02977C9.86169 8.02977 9.85873 8.02829 9.85725 8.02681C9.85428 8.02681 9.8528 8.02385 9.84984 8.02237C9.84984 8.02237 9.84688 8.02237 9.84539 8.02088C9.84243 8.02088 9.84095 8.01792 9.83799 8.01792C9.83651 8.01792 9.83502 8.01792 9.83354 8.01644C9.83058 8.01644 9.8291 8.01496 9.82614 8.01348C9.82465 8.01348 9.82317 8.01348 9.82169 8.01348C9.81873 8.01348 9.81725 8.01348 9.81428 8.012C9.8128 8.012 9.80984 8.012 9.80836 8.012C9.80539 8.012 9.80391 8.012 9.80095 8.012C9.79799 8.012 9.79651 8.012 9.79354 8.012C9.79206 8.012 9.79058 8.012 9.7891 8.012H9.31058C9.23799 8.012 9.17725 8.07126 9.17725 8.14385V11.1127C9.17725 11.1853 9.23651 11.2446 9.31058 11.2446H9.7891C9.86317 11.2446 9.92243 11.1853 9.92243 11.1127V9.34977L11.2854 11.1898C11.2943 11.2031 11.3061 11.2135 11.3195 11.2224C11.3224 11.2238 11.3254 11.2253 11.3284 11.2268C11.3284 11.2268 11.3313 11.2268 11.3328 11.2283C11.3343 11.2283 11.3372 11.2298 11.3387 11.2313C11.3402 11.2313 11.3432 11.2327 11.3447 11.2342C11.3447 11.2342 11.3476 11.2342 11.3491 11.2357C11.3521 11.2357 11.355 11.2372 11.358 11.2387C11.358 11.2387 11.358 11.2387 11.3595 11.2387C11.3698 11.2416 11.3817 11.2431 11.3935 11.2431H11.8721C11.9461 11.2431 12.0054 11.1838 12.0054 11.1113V8.14237C12.0054 8.06977 11.9461 8.01051 11.8721 8.01051"
      fill="#00B900"
    />
    <path
      d="M7.43202 10.5009H6.13276V8.14237C6.13276 8.06977 6.0735 8.00903 5.99943 8.00903H5.52091C5.44831 8.00903 5.38757 8.06829 5.38757 8.14237V11.1113C5.38757 11.1468 5.40239 11.1794 5.42461 11.2031C5.42461 11.2031 5.42461 11.2031 5.42609 11.2046C5.42609 11.2046 5.42609 11.2046 5.42757 11.2061C5.45128 11.2283 5.48387 11.2431 5.51943 11.2431H7.42905C7.50165 11.2431 7.56091 11.1838 7.56091 11.1098V10.6313C7.56091 10.5587 7.50165 10.4979 7.42905 10.4979"
      fill="#00B900"
    />
    <path
      d="M14.5135 8.75275C14.5861 8.75275 14.6454 8.69349 14.6454 8.61942V8.1409C14.6454 8.06683 14.5861 8.00757 14.5135 8.00757H12.6039C12.5683 8.00757 12.5357 8.02238 12.512 8.04461C12.512 8.04461 12.512 8.04461 12.5106 8.04461C12.5106 8.04461 12.5091 8.04609 12.5091 8.04757C12.4869 8.07127 12.472 8.10386 12.472 8.13942V11.1083C12.472 11.1439 12.4869 11.1765 12.5091 11.2002C12.5091 11.2002 12.5091 11.2002 12.5106 11.2016C12.5106 11.2016 12.5106 11.2016 12.512 11.2031C12.5357 11.2253 12.5683 11.2402 12.6039 11.2402H14.5135C14.5861 11.2402 14.6454 11.1809 14.6454 11.1068V10.6283C14.6454 10.5557 14.5861 10.495 14.5135 10.495H13.2143V9.99275H14.5135C14.5861 9.99275 14.6454 9.93349 14.6454 9.85942V9.3809C14.6454 9.30683 14.5861 9.24757 14.5135 9.24757H13.2143V8.74535H14.5135V8.75275Z"
      fill="#00B900"
    />
  </svg>
);

export default LineIcon;
