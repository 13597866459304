import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const MyNotificationIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width={18}
      height={14}
      viewBox="0 0 18 14"
      fill="none"
      {...props}
      className={twMerge("fill-[#C2D6D9]", props.className)}
    >
      <path d="M17.065 0h-.935a.932.932 0 00-.935.928v12.07c0 .512.419.928.935.928h.935a.932.932 0 00.935-.929V.928A.932.932 0 0017.066 0zM13.727 1.337L3.207 4.275a.229.229 0 00-.168.223v7.385c0 .757.552 1.402 1.3 1.528l3.427.566c.084.014.173.023.257.023.365 0 .72-.13 1.005-.367.35-.292.552-.724.552-1.179v-.65l4.147 1.115c.15.041.294-.07.294-.223V1.56a.235.235 0 00-.299-.223h.005zM8.182 12.45c0 .06-.028.097-.056.116a.131.131 0 01-.127.032l-3.427-.566a.159.159 0 01-.13-.153v-1.467l3.74 1.007v1.031zM1.636 4.642h-.7A.934.934 0 000 5.57v3.25c0 .51.42.928.935.928h.701a.23.23 0 00.234-.232V4.874a.23.23 0 00-.234-.232z" />
    </svg>
  );
};
