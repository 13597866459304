"use client";

import { Link } from "next-view-transitions";
import Image, { StaticImageData } from "next/image";
import { twMerge } from "tailwind-merge";

import { externalLinks } from "@/data/links/external-links";
import { useLang } from "@/hooks/use-lang";
import { usePath } from "@/hooks/use-path";
import androidButton from "@/public/footer/android.png";
import iosButton from "@/public/footer/ios.png";

import { getFooterLinkLabel, getFooterLinks } from "../data/footer";

const FooterLinks = () => {
  const { isStockMiningPage } = usePath();
  const { t } = useLang();
  const footerLinks = getFooterLinks(t);
  const firstGroup = footerLinks.slice(0, 2);
  const secondGroup = footerLinks.slice(2, 4);

  return (
    <div
      className={twMerge(
        "grid w-full grid-flow-col auto-rows-min gap-x-8 gap-y-8 max-xl:grid-rows-2",
        !isStockMiningPage ? "max-md:grid-rows-5" : "max-md:grid-rows-4"
      )}
    >
      {firstGroup.map((group) => (
        <section key={group.title} className="flex flex-col gap-y-3">
          <p className="select-none font-bold">{group.title}</p>
          {group.links.map((link) => (
            <FooterLink
              key={link.title}
              title={link.title}
              url={link.url}
              label={link.label}
              icon={link?.icon}
            />
          ))}
        </section>
      ))}
      {!isStockMiningPage && (
        <div className="flex flex-col gap-3">
          <p className="select-none font-bold">
            {t.layout.footer.downloadRoboApp}
          </p>
          <ImageLink
            url={externalLinks.growinAppStore}
            image={iosButton}
            childrenLabel={getFooterLinkLabel({ name: "app store" })}
          />
          <ImageLink
            url={externalLinks.growinGooglePlay}
            image={androidButton}
            childrenLabel={getFooterLinkLabel({ name: "google play" })}
          />
        </div>
      )}
      {secondGroup.map((group) => (
        <section key={group.title} className="flex flex-col gap-y-3">
          <p className="select-none font-bold">{group.title}</p>
          {group.links.map((link) => (
            <FooterLink
              key={link.title}
              title={link.title}
              url={link.url}
              label={link.label}
              icon={link?.icon}
            />
          ))}
        </section>
      ))}
    </div>
  );
};

const ImageLink = ({
  url,
  image,
  childrenLabel,
}: {
  url: string;
  image: StaticImageData;
  childrenLabel: string;
}) => (
  <Link
    href={url}
    target="_blank"
    rel="noreferrer"
    className="relative h-10 w-32"
    aria-label={childrenLabel}
  >
    <Image src={image} alt={childrenLabel} fill sizes="100%" />
  </Link>
);

const FooterLink = ({
  title,
  url,
  icon,
  label,
}: {
  title: string;
  url: string;
  label?: string;
  icon?: React.ReactNode;
}) => (
  <Link
    href={url}
    target={url.includes("http") ? "_blank" : "_self"}
    rel="noreferrer"
    className="w-fit transition hover:scale-95"
    aria-label={label || title}
  >
    <span className="flex select-none items-center space-x-2">
      {icon && <span className="flex h-5 w-5 items-center">{icon}</span>}
      <p className="cursor-pointer text-sm font-normal">{title}</p>
    </span>
  </Link>
);

export default FooterLinks;
