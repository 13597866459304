"use client";

import { usePathname } from "next/navigation";
import { twMerge } from "tailwind-merge";

import { useAuth } from "@/features/auth/shared/hooks/use-auth";
import { useLogout } from "@/features/auth/shared/services/logout";
import { useLang } from "@/hooks/use-lang";

import { getMainMenuItems } from "../data/main-menu";
import { getUserMenuItems } from "../data/user-menu";
import { MainMenuMobile } from "./MainMenuMobile";
import { MainMenuWeb } from "./MainMenuWeb";

const gradientBackgroundPages = ["stockmining", "winwingpt-waitlist"];

export const Navbar = () => {
  const { t, lang } = useLang();
  const pathname = usePathname() ?? "";
  const isGradientBackground = gradientBackgroundPages.some((page) =>
    pathname.includes(page)
  );
  const { profile } = useAuth();
  const email = profile?.email ?? "";

  const { mutate: logout } = useLogout();
  const { isAuthenticated } = useAuth();

  const mainMenuItems = getMainMenuItems({ t, lang });
  const userMenuItems = getUserMenuItems({
    t,
    lang,
    isLogin: isAuthenticated,
    onLogout: logout,
    email,
  });

  return (
    <header
      className={twMerge(
        "sticky z-50 flex w-full shadow",
        isGradientBackground
          ? "bg-gradient-to-r from-[#0369A1] to-[#18181B]"
          : "bg-white"
      )}
    >
      <MainMenuWeb
        mainMenu={mainMenuItems}
        userMenu={userMenuItems}
        isLogin={isAuthenticated}
        isStockMiningPage={isGradientBackground}
        className="hidden md:flex"
      />
      <MainMenuMobile
        mainMenu={mainMenuItems}
        userMenu={userMenuItems}
        isLogin={isAuthenticated}
        isStockMiningPage={isGradientBackground}
        className="md:hidden"
      />
    </header>
  );
};
