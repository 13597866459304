import Link from "next/link";

type Props = {
  to: string;
  title: string;
  isStockMiningPage: boolean;
  blank?: boolean;
  pathname?: string;
  onClick?: () => void;
  childrenLabel?: string;
};

export const MenuLinkMobile = ({
  to,
  title,
  isStockMiningPage,
  blank,
  pathname,
  onClick,
  childrenLabel,
}: Props) => {
  const isSelected = to === pathname;
  return (
    <Link
      href={to}
      onClick={onClick}
      target={blank ? "_blank" : ""}
      rel="noreferrer"
      className={`flex flex-col gap-3 py-2 text-sm sm:text-base ${
        isSelected
          ? isStockMiningPage
            ? "text-[#2D80AF]"
            : "text-primary"
          : "text-black"
      }`}
      aria-label={childrenLabel}
    >
      {title}
    </Link>
  );
};
