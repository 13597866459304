import { animated, useTransition } from "@react-spring/web";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { ComponentProps, useState } from "react";
import { twMerge } from "tailwind-merge";

import { FullGrowinLogo } from "@/components/growin-logo/FullGrowinLogo";
import { LangSwitch } from "@/components/lang-switch";
import { NAVBAR_HEIGHT } from "@/data/constants/layout";
import { links } from "@/data/links";
import { useLang } from "@/hooks/use-lang";

import { Button } from "ui/core/button";

import { GuestMenu } from "../data/interface/guest-menu";
import { MainMenuItem } from "../data/interface/main-menu";
import { UserMenu } from "../data/interface/user-menu";
import { DropDown } from "./DropDown";
import { MenuLinkMobile } from "./MenuLinkMobile";

type MainMenuMobileProps = {
  isLogin: boolean;
  isStockMiningPage: boolean;
  userMenu: UserMenu | GuestMenu;
  mainMenu: MainMenuItem[];
} & ComponentProps<"nav">;

export const MainMenuMobile = ({
  mainMenu,
  userMenu,
  isLogin,
  isStockMiningPage,
  ...props
}: MainMenuMobileProps) => {
  const { lang } = useLang();
  const pathname = usePathname() ?? "/";
  const [isOpen, setOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 250 },
  });

  return (
    <nav
      {...props}
      className={twMerge(
        "relative flex h-[var(--navbar-height)] w-full items-center justify-between px-7 py-4",
        props.className
      )}
    >
      <Link
        href={`/${lang}${links.home}`}
        className="transition hover:scale-105"
      >
        <FullGrowinLogo
          className={isStockMiningPage ? "fill-white" : "fill-[#3AD29F]"}
        />
      </Link>
      <MenuButton
        isStockMiningPage={isStockMiningPage}
        isOpen={isOpen}
        onClick={() => setOpen((prev) => !prev)}
      />
      {transitions(
        (styles, isOpen) =>
          isOpen && (
            <animated.div
              className="absolute right-0 z-50 w-full bg-white px-7 py-5 shadow"
              style={{
                ...styles,
                top: NAVBAR_HEIGHT,
                height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
              }}
            >
              {/* item 選項 */}
              {mainMenu.map((item) => (
                <React.Fragment key={`mainMenuPhoneDropDown${item.title}`}>
                  {item.sections ? (
                    <DropDown
                      menu={item}
                      activeDropdown={activeDropdown}
                      setActiveDropdown={setActiveDropdown}
                      isStockMiningPage={isStockMiningPage}
                      isMobile={true}
                      setOpen={setOpen}
                    />
                  ) : (
                    <MenuLinkMobile
                      isStockMiningPage={isStockMiningPage}
                      to={item.to ?? ""}
                      title={item.title}
                      blank={item.blank}
                      pathname={pathname}
                      childrenLabel={
                        item.ariaLabel ? `${item.ariaLabel} mobile` : undefined
                      }
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  )}
                  <div className="my-[5px] h-[0.5px] w-full bg-stone-300" />
                </React.Fragment>
              ))}
              {/* user 選項 */}
              <div className="flex w-full flex-col items-center gap-6">
                {isLogin ? (
                  <div className="-mt-2 w-full">
                    <DropDown
                      key={`mainMenuPhoneDropDown${userMenu.title}`}
                      menu={userMenu}
                      activeDropdown={activeDropdown}
                      setActiveDropdown={setActiveDropdown}
                      isStockMiningPage={isStockMiningPage}
                      isMobile={true}
                      setOpen={setOpen}
                    />
                  </div>
                ) : (
                  <div className="flex w-full items-center justify-center gap-3 pt-5">
                    {(userMenu as GuestMenu).options.map((item, index) => (
                      <Link
                        href={item.href}
                        target={item.blank ? "_blank" : "_self"}
                        key={item.title}
                        aria-label={
                          item.ariaLabel
                            ? `${item.ariaLabel} mobile`
                            : undefined
                        }
                        className="mx-1 w-full max-w-[120px] whitespace-nowrap"
                      >
                        <Button
                          variant={index === 1 ? "primary" : "secondary"}
                          className={twMerge(
                            "w-full",
                            !isStockMiningPage &&
                              index === 1 &&
                              "bg-emerald-500 active:bg-emerald-300"
                          )}
                          onClick={() => setOpen(false)}
                        >
                          {item.title}
                        </Button>
                      </Link>
                    ))}
                  </div>
                )}
                <LangSwitch colorScheme="green" />
              </div>
            </animated.div>
          )
      )}
    </nav>
  );
};

const MenuButton = ({
  isStockMiningPage,
  isOpen,
  onClick,
}: {
  isStockMiningPage: boolean;
  isOpen: boolean;
  onClick: () => void;
}) => (
  <div
    role="button"
    className={`relative h-4 w-6 cursor-pointer ${
      isStockMiningPage ? "[&_span]:bg-white" : "[&_span]:bg-[#3ad29f]"
    }`}
    onClick={onClick}
    aria-label="mobile menu button"
    aria-expanded={isOpen}
  >
    <span
      className={`absolute left-0 top-0 h-[3px] w-full rounded-lg transition ${
        isOpen ? "top-[8px] rotate-45" : "top-0"
      }`}
    />
    <span
      className={`absolute left-0 top-[7px] h-[3px] w-full rounded-lg transition ${
        isOpen ? "opacity-0" : "opacity-100"
      }`}
    />
    <span
      className={`absolute left-0 h-[3px] w-full rounded-lg transition ${
        isOpen ? "top-[8px] -rotate-45" : "top-[15px]"
      }`}
    />
  </div>
);
