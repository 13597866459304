type DropDownTitleProps = {
  children: React.ReactNode;
  active: boolean;
  isStockMiningPage: boolean;
  isMobile: boolean;
  childrenLabel?: string;
};

export const DropDownTitle = ({
  children,
  active,
  isStockMiningPage,
  isMobile,
  childrenLabel,
}: DropDownTitleProps) => (
  <span
    className={`whitespace-nowrap transition-all duration-200 ${
      isStockMiningPage && !isMobile ? "text-white" : "text-black"
    }`}
    role="button"
    aria-label={childrenLabel}
    aria-expanded={active}
  >
    {children}
    <span
      className={`mt-0.5 block h-0.5 transition-all duration-200 ${
        active ? "max-w-full" : "max-w-0"
      } ${
        isStockMiningPage
          ? isMobile
            ? "bg-[#2D80AF]"
            : "bg-white"
          : "bg-[#2ABA77]"
      }`}
    />
  </span>
);
