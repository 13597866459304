import { useMutation } from "@tanstack/react-query";
import { signOut } from "next-auth/react";
import { z } from "zod";

import { NIKE_URL } from "@/data/constants/url";
import { useLang } from "@/hooks/use-lang";
import { Lang } from "@/i18n-config";

import { useAuth } from "../hooks/use-auth";

type BusinessLogoutParams = {
  token: string;
  lang: Lang;
};

const responseDataSchema = z.object({
  message: z.string(),
});

export const logout = async ({ token, lang }: BusinessLogoutParams) => {
  const res = await fetch(`${NIKE_URL}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Profile": token,
      "Accept-Language": lang,
    },
  });

  if (!res.ok) throw new Error("Logout failed");
  return responseDataSchema.parse(await res.json()).message;
};

export const useLogout = () => {
  const { lang } = useLang();
  const { backendToken } = useAuth();

  return useMutation(() => logout({ token: backendToken ?? "", lang }), {
    onSettled: async () => {
      await signOut();
      window?.Intercom?.("shutdown");
    },
  });
};
