import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const StockMiningPricingIcon = (props: ComponentProps<"svg">) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={twMerge("fill-[#C2D6D9]", props.className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.51334 8.35152L11.0865 9.28014C11.1131 9.29005 11.136 9.30786 11.1522 9.33118C11.1684 9.3545 11.1771 9.38221 11.1771 9.4106C11.1771 9.43899 11.1684 9.46671 11.1522 9.49003C11.136 9.51334 11.1131 9.53115 11.0865 9.54106L8.51334 10.4705C8.08437 10.6254 7.69479 10.8729 7.37232 11.1955C7.04985 11.5181 6.80242 11.9078 6.64764 12.3369L5.71937 14.9103C5.70946 14.9369 5.69166 14.9598 5.66835 14.976C5.64504 14.9922 5.61734 15.0009 5.58896 15.0009C5.56058 15.0009 5.53288 14.9922 5.50957 14.976C5.48626 14.9598 5.46846 14.9369 5.45855 14.9103L4.52945 12.3369C4.37484 11.908 4.12767 11.5184 3.8055 11.1958C3.48333 10.8732 3.09407 10.6256 2.66541 10.4705L0.0905923 9.54106C0.0639954 9.53115 0.041063 9.51334 0.0248705 9.49003C0.00867813 9.46671 0 9.43899 0 9.4106C0 9.38221 0.00867813 9.3545 0.0248705 9.33118C0.041063 9.30786 0.0639954 9.29005 0.0905923 9.28014L2.66541 8.35152C3.09388 8.19659 3.483 7.94921 3.80515 7.62693C4.1273 7.30466 4.37459 6.91539 4.52945 6.48676L5.45855 3.91094C5.46846 3.88433 5.48626 3.86139 5.50957 3.84519C5.53288 3.82899 5.56058 3.82031 5.58896 3.82031C5.61734 3.82031 5.64504 3.82899 5.66835 3.84519C5.69166 3.86139 5.70946 3.88433 5.71937 3.91094L6.64764 6.48676C6.80247 6.91587 7.04992 7.30557 7.37238 7.62816C7.69484 7.95075 8.0844 8.19829 8.51334 8.35318"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4507 2.63295L14.9506 3.17312C14.9651 3.17975 14.9775 3.19043 14.9861 3.20388C14.9948 3.21733 14.9994 3.23299 14.9994 3.24898C14.9994 3.26497 14.9948 3.28063 14.9861 3.29408C14.9775 3.30753 14.9651 3.31821 14.9506 3.32484L13.4507 3.86501C13.2017 3.95516 12.9757 4.09896 12.7885 4.28623C12.6013 4.4735 12.4575 4.69966 12.3674 4.94869L11.8275 6.44916C11.8208 6.46372 11.8102 6.47606 11.7967 6.48471C11.7833 6.49336 11.7676 6.49796 11.7516 6.49796C11.7356 6.49796 11.72 6.49336 11.7065 6.48471C11.6931 6.47606 11.6824 6.46372 11.6758 6.44916L11.1358 4.94869C11.0457 4.69966 10.902 4.4735 10.7148 4.28623C10.5276 4.09896 10.3015 3.95516 10.0526 3.86501L8.55269 3.32484C8.53814 3.31821 8.5258 3.30753 8.51715 3.29408C8.50851 3.28063 8.50391 3.26497 8.50391 3.24898C8.50391 3.23299 8.50851 3.21733 8.51715 3.20388C8.5258 3.19043 8.53814 3.17975 8.55269 3.17312L10.0526 2.63295C10.3015 2.54281 10.5276 2.399 10.7148 2.21173C10.902 2.02446 11.0457 1.7983 11.1358 1.54927L11.6758 0.0487981C11.6824 0.0342445 11.6931 0.0219055 11.7065 0.0132531C11.72 0.00460062 11.7356 0 11.7516 0C11.7676 0 11.7833 0.00460062 11.7967 0.0132531C11.8102 0.0219055 11.8208 0.0342445 11.8275 0.0487981L12.3674 1.54927C12.4575 1.7983 12.6013 2.02446 12.7885 2.21173C12.9757 2.399 13.2017 2.54281 13.4507 2.63295Z"
    />
  </svg>
);
