import { useWindowSize } from "hooks/use-window-size";
import { Link } from "next-view-transitions";
import { ComponentProps, useState } from "react";
import { twMerge } from "tailwind-merge";

import { FullGrowinLogo } from "@/components/growin-logo/FullGrowinLogo";
import { LangSwitch } from "@/components/lang-switch";
import { links } from "@/data/links";
import { useLang } from "@/hooks/use-lang";

import { Button } from "ui/core/button";

import { GuestMenu, GuestMenuOption } from "../data/interface/guest-menu";
import { MainMenuItem } from "../data/interface/main-menu";
import { UserMenu } from "../data/interface/user-menu";
import { DropDown } from "./DropDown";
import { MenuLink } from "./MenuLink";

type Props = {
  isLogin: boolean;
  isStockMiningPage: boolean;
  userMenu: UserMenu | GuestMenu;
  mainMenu: MainMenuItem[];
} & ComponentProps<"nav">;

export const MainMenuWeb = ({
  mainMenu,
  userMenu,
  isLogin,
  isStockMiningPage,
  ...props
}: Props) => {
  const { width = 0 } = useWindowSize();
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  return (
    <nav
      {...props}
      className={twMerge(
        "z-[100] mx-auto flex h-[var(--navbar-height)] w-full max-w-7xl items-center justify-between px-3",
        props.className
      )}
    >
      <MainNavigation
        mainMenu={mainMenu}
        isStockMiningPage={isStockMiningPage}
        width={width}
        activeDropdown={activeDropdown}
        setActiveDropdown={setActiveDropdown}
      />
      <UserNavigation
        isLogin={isLogin}
        userMenu={userMenu}
        isStockMiningPage={isStockMiningPage}
        activeDropdown={activeDropdown}
        setActiveDropdown={setActiveDropdown}
      />
    </nav>
  );
};

const MainNavigation = ({
  isStockMiningPage,
  mainMenu,
  width,
  activeDropdown,
  setActiveDropdown,
}: {
  isStockMiningPage: boolean;
  mainMenu: MainMenuItem[];
  width: number;
  activeDropdown: string | null;
  setActiveDropdown: (value: string) => void;
}) => {
  const { t, lang } = useLang();
  return (
    <div className="flex h-full items-center gap-8 px-6">
      <Link
        href={`/${lang}${links.home}`}
        className="transition hover:scale-105"
      >
        <FullGrowinLogo
          className={isStockMiningPage ? "fill-white" : "fill-[#3AD29F]"}
        />
      </Link>
      <div className="flex items-center gap-5">
        {mainMenu.map((item, index) => {
          if (!!width && width < 1000) {
            if ([t.layout.navbar.companyIntro, "YouTube"].includes(item.title))
              return;
          }
          // 下拉式選單
          if (item.sections) {
            return (
              <DropDown
                key={`mainMenu${index}`}
                menu={item}
                activeDropdown={activeDropdown}
                setActiveDropdown={(val) => setActiveDropdown(val ?? "")}
                isStockMiningPage={isStockMiningPage}
              />
            );
          }
          // 一般選項
          return (
            <MenuLink
              key={`mainMenu${index}`}
              to={item.to ?? ""}
              title={item.title}
              blank={item.blank}
              isStockMiningPage={isStockMiningPage}
              childrenLabel={item.ariaLabel}
            />
          );
        })}
      </div>
    </div>
  );
};

const UserNavigation = ({
  isLogin,
  userMenu,
  isStockMiningPage,
  activeDropdown,
  setActiveDropdown,
}: {
  isLogin: boolean;
  userMenu: UserMenu | GuestMenu;
  isStockMiningPage: boolean;
  activeDropdown: string | null;
  setActiveDropdown: (value: string) => void;
}) => (
  <div
    className={twMerge(
      "flex items-center justify-end gap-6 lg:gap-10",
      isLogin ? "pr-0" : "pr-10"
    )}
  >
    <LangSwitch colorScheme={isStockMiningPage ? "white" : "green"} />
    {isLogin ? (
      <div className="pr-10">
        <DropDown
          menu={userMenu}
          activeDropdown={activeDropdown}
          setActiveDropdown={(val) => setActiveDropdown(val ?? "")}
          isStockMiningPage={isStockMiningPage}
        />
      </div>
    ) : (
      (userMenu as GuestMenu).options && (
        <Actions
          isStockMiningPage={isStockMiningPage}
          options={
            (userMenu as GuestMenu).options as [
              GuestMenuOption,
              GuestMenuOption
            ]
          }
        />
      )
    )}
  </div>
);

const Actions = ({
  options,
  isStockMiningPage,
}: {
  options: [GuestMenuOption, GuestMenuOption];
  isStockMiningPage: boolean;
}) => {
  const [loginItem, startItem] = options;
  return (
    <div className="flex gap-4">
      <Link
        href={loginItem.href}
        target={loginItem.blank ? "_blank" : "_self"}
        aria-label={loginItem.ariaLabel}
      >
        <Button
          className={twMerge(
            "w-[120px] bg-transparent text-emerald-500 ring-emerald-500",
            isStockMiningPage && "text-white ring-white hover:bg-transparent"
          )}
          variant="secondary"
        >
          {loginItem.title}
        </Button>
      </Link>
      <Link
        href={startItem.href}
        target={startItem.blank ? "_blank" : "_self"}
        aria-label={startItem.ariaLabel}
      >
        <Button
          className={twMerge(
            "w-[120px] bg-emerald-500 hover:bg-emerald-400",
            isStockMiningPage && "bg-white text-sky-700 hover:bg-gray-100"
          )}
        >
          {startItem.title}
        </Button>
      </Link>
    </div>
  );
};
