import  { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const MyBlogIcon = (props: ComponentProps<"svg">) => (
  <svg
    {...props}
    width={15}
    height={15}
    viewBox="-1 0 20 20"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className={twMerge("stroke-[#C2D6D9]", props.className)}
  >
  <path d="M10.75 16.82A7.462 7.462 0 0 1 15 15.5c.71 0 1.396.098 2.046.282A.75.75 0 0 0 18 15.06v-11a.75.75 0 0 0-.546-.721A9.006 9.006 0 0 0 15 3a8.963 8.963 0 0 0-4.25 1.065V16.82ZM9.25 4.065A8.963 8.963 0 0 0 5 3c-.85 0-1.673.118-2.454.339A.75.75 0 0 0 2 4.06v11a.75.75 0 0 0 .954.721A7.506 7.506 0 0 1 5 15.5c1.579 0 3.042.487 4.25 1.32V4.065Z" />
  </svg>
);

