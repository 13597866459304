"use client";

import { UserIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import { twMerge } from "tailwind-merge";

import { useAuth } from "@/features/auth/shared/hooks/use-auth";

interface UserAvatarProps {
  width: number;
  height: number;
  className?: string;
}

export const UserAvatar = ({ width, height, className }: UserAvatarProps) => {
  const { profile } = useAuth();
  if (!profile)
    return (
      <CustomAvatar width={width} height={height} className="bg-zinc-100">
        <UserIcon
          width={Math.round(width * 0.5)}
          height={Math.round(width * 0.5)}
          className={twMerge("text-zinc-400", className)}
        />
      </CustomAvatar>
    );

  const { name, image } = profile;

  return image ? (
    <Image
      src={image}
      alt="user avatar"
      className={twMerge("rounded-full", className)}
      style={{ minWidth: width }}
      width={width}
      height={height}
      aria-label="user avatar"
    />
  ) : (
    <CustomAvatar width={width} height={height} className={className}>
      {name?.[0]?.toUpperCase() ?? "🌱"}
    </CustomAvatar>
  );
};

type CustomAvatarProps = {
  width: number;
  height: number;
  children: React.ReactNode;
  className?: string;
};

const CustomAvatar = ({
  width,
  height,
  children,
  className,
}: CustomAvatarProps) => (
  <figure
    className={twMerge(
      `flex shrink-0 select-none items-center justify-center rounded-full bg-sky-600 text-sm font-bold text-white`,
      className
    )}
    style={{ width, height }}
    aria-label="user avatar"
  >
    {children}
  </figure>
);
