import { Translation } from "@/data/lang";
import { links } from "@/data/links";
import { externalLinks } from "@/data/links/external-links";
import { Lang } from "@/i18n-config";

import { Icons } from "./icons";
import { MainMenuItem } from "./interface/main-menu";

const getNavbarLinkLabel = ({ name }: { name: string }) =>
  `go to ${name} page from navbar`;

type Props = {
  t: Translation;
  lang: Lang;
};

export const getMainMenuItems = ({ t, lang }: Props): MainMenuItem[] => {
  return [
    {
      title: t.layout.navbar.product,
      sections: [
        {
          options: [
            {
              to: `/${lang}${links.stockMining}`,
              title: t.layout.navbar.stockMining,
              icon: Icons.stockMining,
              ariaLabel: getNavbarLinkLabel({ name: "stock mining" }),
            },
            {
              to: `/${lang}${links.stockMiningPricing}`,
              title: t.layout.navbar.stockMiningPricing,
              icon: Icons.stockMiningPricing,
              ariaLabel: getNavbarLinkLabel({ name: "stock mining pricing" }),
            },
          ],
        },
      ],
    },
    {
      to: externalLinks.blog,
      title: "Blog",
      blank: true,
      ariaLabel: getNavbarLinkLabel({ name: "blog" }),
    },
    {
      to: externalLinks.youtube,
      title: "YouTube",
      blank: true,
      ariaLabel: getNavbarLinkLabel({ name: "youtube" }),
    },
    {
      to: `/${lang}${links.about}`,
      title: t.layout.navbar.companyIntro,
      ariaLabel: getNavbarLinkLabel({ name: "about" }),
    },
    {
      to: `/${lang}${links.contact}`,
      title: t.layout.navbar.contactUs,
      ariaLabel: getNavbarLinkLabel({ name: "contact" }),
    },
  ];
};
