import  { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const MyMediaIcon = (props: ComponentProps<"svg">) => (
  <svg
    width={15}
    height={15}
    preserveAspectRatio="xMidYMid meet"
    viewBox="-1 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={twMerge("fill-[#C2D6D9]", props.className)}
  >
    <path d="M14.314 4.06466C14.1504 3.44878 13.6685 2.96372 13.0566 2.79911C11.9475 2.5 7.50004 2.5 7.50004 2.5C7.50004 2.5 3.05264 2.5 1.9435 2.79911C1.3316 2.96375 0.849671 3.44878 0.686103 4.06466C0.388916 5.18099 0.388916 7.5101 0.388916 7.5101C0.388916 7.5101 0.388916 9.83922 0.686103 10.9555C0.849671 11.5714 1.3316 12.0363 1.9435 12.2009C3.05264 12.5 7.50004 12.5 7.50004 12.5C7.50004 12.5 11.9474 12.5 13.0566 12.2009C13.6685 12.0363 14.1504 11.5714 14.314 10.9555C14.6112 9.83922 14.6112 7.5101 14.6112 7.5101C14.6112 7.5101 14.6112 5.18099 14.314 4.06466ZM6.04548 9.62477V5.39544L9.76264 7.51016L6.04548 9.62477Z" />
  </svg>
);
