import { Link } from "next-view-transitions";
import { twMerge } from "tailwind-merge";

import { GrowinColors } from "ui/colors";

type Props = {
  to: string;
  title: string;
  blank?: boolean;
  isStockMiningPage: boolean;
  childrenLabel?: string;
};

export const MenuLink = ({
  to,
  title,
  blank,
  isStockMiningPage,
  childrenLabel,
}: Props) => (
  <Link
    href={to}
    target={blank ? "_blank" : ""}
    rel="noreferrer"
    onMouseEnter={(e) => {
      (e.currentTarget.children[0] as HTMLElement).style.maxWidth = "100%";
    }}
    onMouseLeave={(e) => {
      (e.currentTarget.children[0] as HTMLElement).style.maxWidth = "0px";
    }}
    aria-label={childrenLabel}
    className={twMerge(
      "mt-1 whitespace-nowrap transition duration-100",
      isStockMiningPage ? "text-white" : "text-black"
    )}
  >
    {title}
    <span
      style={{
        height: "2px",
        marginTop: "2px",
        display: "block",
        backgroundColor: isStockMiningPage
          ? "white"
          : GrowinColors["growin-primary"],
        maxWidth: "0px",
        transition: "all 200ms",
      }}
    />
  </Link>
);
