import Divider from "@mui/material/Divider";
import { useWindowSize } from "hooks/use-window-size";
import { Link } from "next-view-transitions";
import React from "react";
import { twMerge } from "tailwind-merge";

import { links } from "@/data/links";
import { useLang } from "@/hooks/use-lang";

import { ArrowDownIcon } from "ui/icon/shared";

import { GuestMenu } from "../data/interface/guest-menu";
import {
  MainMenuItem,
  MainMenuItemSection,
  MainMenuItemSectionOption,
} from "../data/interface/main-menu";
import {
  UserMenu,
  UserMenuSection,
  UserMenuSectionOption,
} from "../data/interface/user-menu";
import { DropDownTitle } from "./DropDownTitle";

type DropDownProps = {
  menu: MainMenuItem | UserMenu | GuestMenu;
  activeDropdown: string | null;
  setActiveDropdown: (value: string | null) => void;
  isStockMiningPage: boolean;
  isMobile?: boolean;
  setOpen?: (value: boolean) => void;
};

const isUserMenu = (
  menu: MainMenuItem | UserMenu | GuestMenu
): menu is UserMenu => (menu as UserMenu).icon !== undefined;

const isGuestMenu = (
  menu: MainMenuItem | UserMenu | GuestMenu
): menu is GuestMenu => !Object.keys(menu).includes("sections");

const isUserMenuSection = (
  section: MainMenuItemSection | UserMenuSection
): section is UserMenuSection =>
  (section as UserMenuSection).showUsername !== undefined;

const isOnClickOption = (
  option: MainMenuItemSectionOption | UserMenuSectionOption
): option is UserMenuSectionOption =>
  (option as UserMenuSectionOption).onClick !== undefined;

export const DropDown = ({
  menu,
  activeDropdown,
  setActiveDropdown,
  isStockMiningPage,
  isMobile = false,
  setOpen,
}: DropDownProps) => {
  const dropdownTitle = menu.title;

  const active = activeDropdown === dropdownTitle;

  const { width = 0 } = useWindowSize();
  const isDrawerMenu = !!width && width < 768;
  const isMobileText = isMobile ? " mobile" : "";

  const { lang } = useLang();

  const handleOnMouseEnter = () => {
    if (active) return;
    setActiveDropdown(dropdownTitle);
  };

  const handleOnMouseLeave = () => {
    if (!active) return;
    setActiveDropdown(null);
  };

  const handleOnClick = () => {
    setTimeout(() => {
      if (active) return setActiveDropdown(null);
      setActiveDropdown(dropdownTitle);
    }, 200);
  };

  if (isGuestMenu(menu)) return <></>;

  return (
    <div
      className="relative pb-2 pt-4 text-sm sm:pb-[10px] sm:text-base"
      onMouseEnter={() => {
        if (!isDrawerMenu) handleOnMouseEnter();
      }}
      onMouseLeave={() => {
        if (!isDrawerMenu) handleOnMouseLeave();
      }}
      onClick={() => {
        if (isDrawerMenu) handleOnClick();
      }}
    >
      {/* 收合選項 */}
      {isUserMenu(menu) && !isDrawerMenu ? (
        <button className="flex cursor-pointer items-center gap-2">
          {menu.icon}
          <DropDownArrow
            className={isStockMiningPage ? "fill-white" : "fill-black"}
            active={active}
          />
        </button>
      ) : (
        <div className="flex cursor-pointer items-center justify-between md:px-1">
          <DropDownTitle
            active={active}
            isStockMiningPage={isStockMiningPage}
            isMobile={isMobile}
            childrenLabel={`open dropdown menu for ${menu.title}${isMobileText}`}
          >
            {menu.title}
          </DropDownTitle>
          {isDrawerMenu && (
            <DropDownArrow className="fill-black" active={active} />
          )}
        </div>
      )}
      {/* 展開內容 */}
      <div
        className={twMerge(
          "mt-2 flex flex-col overflow-hidden whitespace-nowrap bg-white transition-opacity duration-300 md:absolute md:w-max md:flex-row md:rounded-2xl md:border md:border-[#eee] md:px-6 md:py-5 md:shadow-xl",
          active
            ? "visible static opacity-100"
            : "invisible absolute opacity-0 sm:-left-[1000px] sm:-top-0",
          active ? (isUserMenu(menu) ? "right-0 " : "left-0") : ""
        )}
      >
        {menu.sections &&
          menu.sections.map(
            (section: MainMenuItemSection | UserMenuSection, index: number) => (
              <div
                key={`DropDownAreaInnerContainer${index}`}
                className={twMerge(
                  "mt-2 md:mt-0",
                  !isUserMenu(menu) && "md:w-[150px] md:even:ml-9"
                )}
              >
                {section.title && (
                  <>
                    {isUserMenuSection(section) && section.showUsername ? (
                      <Link
                        href={`/${lang}${links.myAccount}`}
                        className="pb-1 text-sm font-medium text-input-border-color"
                        aria-label={`user name${isMobileText}`}
                      >
                        {section.title}
                      </Link>
                    ) : (
                      <div className="cursor-auto text-sm font-medium text-stone-300">
                        {section.title}
                      </div>
                    )}
                    <div className="py-3">
                      <Divider />
                    </div>
                  </>
                )}
                {section.options.map(
                  (
                    item: MainMenuItemSectionOption | UserMenuSectionOption,
                    index: number
                  ) =>
                    isOnClickOption(item) ? (
                      <DropDownItem
                        key={`dropdown-item-${index}`}
                        item={item}
                        onClick={() => {
                          item.onClick?.();
                          setActiveDropdown(null);
                          setOpen?.(false);
                        }}
                        childrenLabel={`${item.ariaLabel ?? ""}${isMobileText}`}
                      />
                    ) : (
                      <Link
                        key={`dropdown-item-${index}`}
                        href={item.to}
                        aria-label={`${item.ariaLabel ?? ""}${isMobileText}`}
                      >
                        <DropDownItem
                          item={item}
                          onClick={() => {
                            setActiveDropdown(null);
                            setOpen?.(false);
                          }}
                        />
                      </Link>
                    )
                )}
              </div>
            )
          )}
      </div>
    </div>
  );
};

const DropDownArrow = ({
  className,
  active,
}: {
  className?: string;
  active: boolean;
}) => (
  <ArrowDownIcon
    className={twMerge(
      "ml-2 h-3 w-3 fill-black transition-transform duration-200",
      className,
      active ? "rotate-180" : "rotate-0"
    )}
  />
);

const DropDownItem = ({
  item,
  onClick,
  childrenLabel,
}: {
  item: MainMenuItemSectionOption | UserMenuSectionOption;
  onClick?: () => void;
  childrenLabel?: string;
}) => (
  <div
    className="group flex cursor-pointer items-center py-[6px] hover:text-[#14b3bc]"
    onClick={onClick}
    role="button"
    aria-label={childrenLabel}
  >
    {item.icon &&
      React.createElement(item.icon, {
        className: "group-hover:fill-[#14b3bc] fill-[#C2D6D9]",
      })}
    <div
      className={`${item.icon ? "ml-[12px]" : ""} flex flex-col items-start`}
    >
      <div className="flex items-center gap-1 text-sm font-medium leading-[15px]">
        <span className="mr-1 leading-[20px]">{item.title}</span>
        {item.attachment && (
          <span className="h-[20px] bg-[#2D80AF] px-1 text-xs font-medium leading-[20px] text-white">
            {item.attachment.text}
          </span>
        )}
      </div>
    </div>
  </div>
);
